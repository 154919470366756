/**
 * @file
 * website media query breakpoints
 */

$mobile-small:		max-width 320px;
$mobile: 			max-width 767px;
//$mobile-large: 	max-width 476px;
$tablet-portrait: 	max-width 768px;
//$tablet-test:	 	max-width 1024px;
$tablet: 			max-width 1024px;
$laptop: 			max-width 1366px;