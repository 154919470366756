/**
 * @file
 * website colors
 */

//$orange:		#e53e2f;
//$grey: 			#7f8080;
//$grey-light:	#f3f3f3;
//$grey-dark:		#bebfbf;
//$white:			#ffffff;
//$off-white:		rgba(243,243,243,.9);