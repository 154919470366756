/**
 * @file
 * HTML setup
 */

* {
	box-sizing: border-box;
   	outline: 0;
}

html {
	height: 100%;
}
 
body {
	color: #4a4a4a;
	font-family: 'niveau-grotesk', sans-serif;
	height: 100%;
	margin: 0;
	padding: 0;
	width: 100%;
}

a {
	color: #7c1803;
	font-family: 'niveau-grotesk', sans-serif;
	font-weight: 700;
	text-decoration: none;

	&:hover {
	//	color: $grey;
	}
}

// Clear iOS styles on specific form elements
input[type="text"],
input[type="email"],
input[type="submit"] {
	-webkit-appearance: none;
	border-radius: 0;
}

input,
textarea {
	//font-family: 'Roboto', sans-serif;
}



.hero {
	height: 60vh;
	position: relative;
	width: 100%;

	@include breakpoint($tablet) {
		height: 57vh;
	}

	@include breakpoint($tablet-portrait) {
		height: 43vh;
	}
}

.hero__images {
	//background: url(/images/coffee2.jpg) center top no-repeat;
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;

	img {
		opacity: 0;
		height: 100%;
		object-fit: cover;
		width: 100%;

		&.sylvia-park.slick-current {
			@media (min-width: 768px) {
				height: 193%;
				transform: translateY(-40vh);
			}

			@media (min-width: 769px) {
				height: 200%;
				transform: translateY(-55vh);
			}
		}
	}

	.slick-track,
	.slick-list {
		height: 100%;

		img {
			//opacity: 1;
		}
	}
}

.hero__images-inner {
	background: rgba(0,0,0,.4);
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1000;
}

.hero__copy {
	color: #fff;
	font-family: 'niveau-grotesk', sans-serif;
	left: 50%;
	position: absolute;
	top: 50%;
	text-align: center;
	transform: translate(-50%,-50%);
	z-index: 1001;


	h2 {
		font-size: 36px;
		letter-spacing: 2px;
		line-height: 42px;
		text-transform: uppercase;

		@include breakpoint($tablet) {
			font-size: 30px;
			letter-spacing: 1.67px;
			line-height: 35px;
		}

		@include breakpoint($mobile) {
			font-size: 24px;
			letter-spacing: 1.33px;
			line-height: 28px;
		}
	}
	
	.logo {
		//background: url(/images/logo.svg) no-repeat;
		display: block;
		height: auto;
		margin: 31px auto 62px;
		max-width: auto;
		width: 668px;

		@include breakpoint($tablet) {
			margin: 16px auto 30px;
		}

		@include breakpoint($tablet-portrait) {
			margin: 16px auto 30px;
			max-width: 580px;
		}

		@include breakpoint($mobile) {
			margin: 10px auto 30px;
			max-width: 330px;
		}

		@include breakpoint($mobile-small) {
			max-width: 280px;
		}
	}
}

.brands {
	margin: 52px auto 82px;
	max-width: 1333px;
	padding: 0 33px;

	display: grid;
	grid-column-gap: 33px;
	grid-template-columns: calc((100% - 99px)/ 4) calc((100% - 99px)/ 4) calc((100% - 99px)/ 4) calc((100% - 99px)/ 4);

	@include breakpoint($tablet) {
		margin: 32px auto 42px;

		grid-template-columns: calc((100% - 33px)/ 2) calc((100% - 33px)/ 2);
		grid-row-gap: 99px;
	}

	@include breakpoint($mobile) {
		margin: 22px auto 32px;

		grid-template-columns: 100%;
	}
}

.brand {
	//float: left;
	//margin: 0 33px 0 0;
	position: relative;
	text-align: center;
	//width: calc((100% - 99px) / 4);

	&:last-child {
		margin: 0;
	}

	@include breakpoint($tablet-portrait) {
		//width: calc((100% - 33px) / 2);
		padding: 0 0 33px;

		&:nth-child(2) {
			margin: 0;
		}
	}

	@include breakpoint($mobile) {
		//width: 100%;
		padding: 0 33px 33px;

		&:nth-child(2) {
			margin: 0;
		}
	}

	@include breakpoint($mobile-small) {
		padding: 0 0 33px;
	}
	
	.brand__logo-container {
		float: left;
		height: 122px;
		position: relative;
		width: 100%;

		@include breakpoint($mobile) {
			height: 100px;
		}

		img {
			height: auto;
			left: 50%;
			top: 50%;
			position: absolute;
			transform: translate(-50%, -50%);

			&.columbus-logo {
				max-width: 86.64383561643836%;
			}

			&.roastco-logo {
				max-width: 71.23287671232877%;
			}

			&.mexico-logo {
				max-width: 41.78082191780822%;
			}

			&.ylc-logo {
				max-width: 55.82191780821918%;
			}
		}
	}

	p {
		float: left;
		font-size: 18px;
		line-height: 1.16;
		margin: 33px 0 20px;

		@include breakpoint($tablet) {
			font-size: 16px;
			margin: 22px 0 12px;
		}

		@include breakpoint($mobile) {
			font-size: 16px;
			margin: 14px 0 8px;
		}
	}

	a {
		display: block;
		font-size: 18px;
		line-height: 1.16;
		margin-bottom: 12px;

		@include breakpoint($tablet) {
			font-size: 16px;
		}

		@include breakpoint($mobile) {
			font-size: 16px;
		}
	}
}

.brand__contact-container {
	bottom: -65px;
	position: absolute;
	width: 100%;

	@include breakpoint($mobile) {
		width: calc(100% - 66px);
	}
}